import React from 'react'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import MainHeading from '@/components/MainHeading'
import { Link, graphql } from 'gatsby'
import Logo from '../images/logo-footer.png'

interface notfoundData {
  data: {
    wp: any
    mainMenu: any
  }
}
// TODO :: Remove this page when prod. This is copy of 404
const NoPage = ({ data }: notfoundData) => {
  const { mainMenu, wp } = data
  const langData = { languages: wp.languages, currentLang: 'fi', translations: [] }
  const { texts } = wp

  return (
    <Layout nav={mainMenu} lang={langData} showhero={false}>
      <Container className="bg-lightlatte -mb-14">
        <div className="w-full flex items-center h-screen flex-col text-center">
          <MainHeading className="mt-20 sm:mt-40">
            {texts.gwNotfoundTitlefi || 'Valitettavasti Etsimääsi sivua ei löydy'}
          </MainHeading>
          <div className="font-bold text-center">
            <p className="mt-20 text-3xl">
              {texts.gwNotfoundTextfi || 'Varmista, että kirjoitit URL-osoitteen oikein.'}
            </p>
            <Link className="mt-10 text-3xl text-blue" to="/">
              {texts.gwNotfoundLinkTextfi || 'Klikkaa tästä takaisin etusivulle'}
            </Link>
            <img alt="Kalevalaseura logo" className="max-w-36 h-auto md:mb-20 mb-5" src={Logo} />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const SearchQuery = graphql`
  query notfound {
    locales: allLocale(filter: { language: { eq: "fi" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    wp {
      ...allLang
      texts: crbThemeOptions {
        gwNotfoundTextfi
        gwNotfoundLinkTextfi
        gwNotfoundTitlefi
      }
    }
    mainMenu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
  }
`

export default NoPage
